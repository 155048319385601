import { domain, openGraphImageUrl } from '$lib/config/system';
import type { SeoConfig } from '$lib/domain/seo-config.interface';
import type { BasicContent } from '$lib/domain/basic-content.interface';

export const organizationSchema: object = {
		'@type': 'Organization',
		name: 'Exoft',
		address: {
				'@type': 'PostalAddress',
				streetAddress: '111a Shevchenko Street',
				addressLocality: 'Lviv',
				postalCode: '79060',
				addressCountry: 'Ukraine'
		},
		contactPoint: [
				{
						'@type': 'ContactPoint',
						telephone: '+380639631064',
						contactType: 'consultant'
				}
		],
};
export const webSiteSchema: object = {
		'@type': 'WebSite',
		url : 'https://exoft.net/',
		name : 'Exoft',
		description : 'Exoft is a custom software development company providing web and mobile app development services' +
				' to help businesses succeed in the digital world.',
		publisher : {
				'@type': 'Organization',
				name: 'Exoft',
				logo: {
						'@type': 'ImageObject',
						url: openGraphImageUrl,
						'width': 1300,
						'height': 925
				}
		},
		sameAs: [
				'https://www.facebook.com/exoft',
				'https://www.instagram.com/exoftsoftware/',
				'https://www.linkedin.com/company/exoft/',
				'https://twitter.com/ExoftSoftware'
		]
};

export const createBlogPostingSchema = (seo: SeoConfig, slug: string): object => ({
		'@type': 'BlogPosting',
		mainEntityOfPage: {
				'@type': 'WebPage',
				'@id': seo.canonical,
		},
		headline: seo.title,
		description: seo.description,
		image: `${domain}/blog/blog-thumb/${slug}.webp`,
		author: {
				'@type': 'Organization',
				name: 'Exoft',
		},
		publisher: {
				'@type': 'Organization',
				name: 'Exoft',
				logo: {
						'@type': 'ImageObject',
						url: openGraphImageUrl,
				},
		},
		datePublished: seo.publishedTime,
		dateModified: seo.modifiedTime,
});

export const createBreadcrumbsSchema = (domain: string, articleName: string, seoCanonical: string): object => ({
		'@type': 'BreadcrumbList',
		itemListElement: [
				{
						'@type': 'ListItem',
						name: 'Exoft',
						position: '1',
						item: `${domain}/`,
				},
				{
						'@type': 'ListItem',
						name: `${seoCanonical.includes('/our-works/') ? 'Our works' : 'Blog'}`,
						position: '2',
						item: `${domain}/${seoCanonical.includes('/our-works/') ? 'our-works' : 'blog'}/`,
				},
				{
						'@type': 'ListItem',
						name: articleName,
						position: '3',
						item: seoCanonical,
				},
		],
});

export const createFaqSchema = (faq: BasicContent[]): object => ({
		'@type': 'FAQPage',
		mainEntity: faq.map(item => ({
				'@type': 'Question',
				name: item.title,
				acceptedAnswer: {
						'@type': 'Answer',
						text: item.content,
				},
		})),
});